<template>
  <ion-page style="background: #fff">
    <ion-content class="text-sm md content-ltr">
      <div class="flex items-center">
        <ion-img
          class="w-[4.5rem] h-[4.5rem] mr-6 rounded-[20%] overflow-hidden md"
          :src="require('@/assets/img/8.png')"
          alt="The Wisconsin State Capitol building in Madison, WI at night"
        ></ion-img>
        <div class="flex-1">
          <p class="text-2xl leading-none text-black">Fortune Rabbit</p>
          <p class="text-xl leading-none text-[#01875F]">AFUN.COM</p>
          <p class="text-[.75rem] text-[#5F6368]">Verificado pelo aplicativo</p>
        </div>
      </div>
      <div class="flex items-center text-sm my-6">
        <div class="flex-1 flex flex-col items-center">
          <p class="text-black font-semibold">4.9 ★</p>
          <p class="text-xs text-[#5F6368]">8M reviews</p>
        </div>
        <i class="h-6 w-[1px] bg-[#E8EAED]"></i>
        <div class="flex-1 flex flex-col items-center">
          <p class="text-black font-semibold">100M +</p>
          <p class="text-xs text-[#5F6368]">Baixando</p>
        </div>
        <i class="h-6 w-[1px] bg-[#E8EAED]"></i>
        <div class="flex-1 flex flex-col items-center">
          <p class="text-black font-semibold">18+</p>
          <p class="text-xs text-[#5F6368]">Rated for 18+</p>
        </div>
      </div>
      <div>
        <div class="shiny" id="modal-install-download" @click="modelFn">
          <div class="btn flex flex-col items-center text-xs">
            <div class="flex items-center">
              <ion-img
                class="w-3 mr-1 md"
                :src="require('@/assets/img/ic_sd.png')"
              ></ion-img>
              <span class="text-sm text-[#ffe336]">Instalação Rápida</span>
            </div>
            Baixe dentro de 15 segundos
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center my-6 text-sm text-[#01875f]">
        <!-- <ion-icon class="text-2xl mr-3 md" role="img" :icon="``"></ion-icon> -->
        <p class="text-2xl ml-6 mr-3 md" style="fill: #01875f">
          <svg
            class="01875f"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="color: #01875f"
          >
            <path
              d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
            ></path>
          </svg>
        </p>
        <p>Share</p>
        <!-- <ion-icon class="text-2xl ml-6 mr-3 md" role="img"></ion-icon> -->
        <p class="text-2xl ml-6 mr-3 md" style="fill: #01875f">
          <svg
            class="XkAcee text-2xl ml-6 mr-3 md"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="color: #01875f"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z"
            ></path>
          </svg>
        </p>

        <p>Add to wishlist</p>
      </div>
      <div class="flex overflow-x-auto">
        <div class="publicUrl">
          <img src="https://upload-us.4y4.xyz/1718803590791/11.png" />
        </div>
        <div class="publicUrl">
          <img src="https://upload-us.4y4.xyz/1718803599497/12.png" />
        </div>
        <div class="publicUrl">
          <img src="https://upload-us.4y4.xyz/1718803609027/14.png" />
        </div>
        <div class="publicUrl">
          <img src="https://upload-us.4y4.xyz/1718803618135/13.png" />
        </div>
      </div>
      <div class="mt-6">
        <div class="text-[#202124] text-lg font-bold flex justify-between mb-3">
          About this app
          <ion-img
            class="w-[.9375rem] md"
            :src="require('@/assets/img/icon-right-arrow.png')"
          ></ion-img>
        </div>
        <p>
          True happiness *The hottest games of 2024* The ringtone of the reels,
          the lights on the screen and the thrill of victory! Bring the wonders
          of Las Vegas to your fingertips! It also brings home the sights and
          sounds of the casino experience and lets your winnings take to the
          skies! The more you spin, the more you win! 1. This product is for use
          by users over the age of 18 and is intended for entertainment purposes
          only.2. This game contains in-app purchases.3. Just because a player
          plays or wins in a social gambling game does not mean that he or she
          will win in real money gambling and related games in the future.
        </p>
      </div>
      <div class="mt-6">
        <div class="text-[#202124] font-bold mb-2">Atualizado em</div>
        <div class="description__content" id="update-time">March 27, 2024</div>
        <!---->
      </div>
      <div class="mt-6">
        <div class="text-[#202124] font-bold text-lg flex justify-between mb-3">
          Segurança dos dados
          <ion-img
            class="w-[.9375rem] md"
            :src="require('@/assets/img/icon-right-arrow.png')"
          ></ion-img>
        </div>
        <div class="description__content" data-t="data_safety.content">
          Sua segurança começa com o entendimento de como os desenvolvedores
          coletam e compartilham seus dados. As práticas de segurança e
          privacidade de dados podem variar de acordo com o uso, a região e a
          idade. O desenvolvedor forneceu as informações a seguir, que podem ser
          atualizadas ao longo do tempo.
        </div>
        <div class="mt-6 border p-6 rounded-lg">
          <div class="flex items-start">
            <div>
              <ion-img
                class="w-4 mr-4 md"
                :src="require('@/assets/img/ic_share.png')"
              ></ion-img>
            </div>
            <div>
              <p>
                Este app pode compartilhar estes tipos de dados com terceiros
              </p>
              <p>
                Local, Informações financeiras e Identificadores do dispositivo
                e outros
              </p>
            </div>
          </div>
          <div class="flex items-start mt-4">
            <div>
              <ion-img
                class="w-4 mr-4 md"
                :src="require('@/assets/img/ic_cloud_upload.png')"
              ></ion-img>
            </div>
            <div>
              <p>
                TEste app pode coletar estes tipos de dados Local, Informações
                pessoais e mais 5
              </p>
            </div>
          </div>
          <div class="flex items-start mt-4">
            <ion-img
              class="w-4 mr-4 md"
              :src="require('@/assets/img/ic_cloud_upload.png')"
            ></ion-img>
            <div>
              <p>Os dados não são criptografados</p>
            </div>
          </div>
          <div class="flex items-start mt-4">
            <ion-img
              class="w-4 mr-4 md"
              :src="require('@/assets/img/ic_delete.png')"
            ></ion-img>
            <div>
              <p>Você pode solicitar a exclusão dos dados</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6">
        <div class="text-[1.1875rem] font-bold mb-3 text-[#202124]">
          Classificações e revisões
        </div>
        <div class="comments__tips">
          As avaliações e avaliações são verificadas e de pessoas que usam o
          mesmo tipo de dispositivo que você usa Telefone As avaliações e
          avaliações são verificadas e de pessoas que usam o mesmo tipo de
          dispositivo que você usa Telefone As avaliações e avaliações são
          verificadas e de pessoas que usam o mesmo tipo de dispositivo que você
          usa Telefone As avaliações e avaliações são verificadas e de pessoas
          que usam o mesmo tipo de dispositivo que você usa Telefone
        </div>
        <div class="mt-6 flex flex-wrap">
          <div
            class="flex border border-[#dadce0] bg-[#e6f3ef] px-3 rounded-[.9375rem] mt-2.5 mr-4 text-[#028760]"
          >
            <ion-img
              class="w-[.8125rem] mr-1 md"
              :src="require('@/assets/img/icon-phone.png')"
            ></ion-img>
            Telephone
          </div>
          <div
            class="flex border border-[#dadce0] px-3 rounded-[.9375rem] mt-2.5 mr-4"
          >
            <ion-img
              class="w-[.8125rem] mr-1 md"
              :src="require('@/assets/img/icon-tablet.png')"
            ></ion-img>
            Tablet
          </div>
          <div
            class="flex border border-[#dadce0] px-3 rounded-[.9375rem] mt-2.5 mr-4"
          >
            <ion-img
              class="w-[.8125rem] mr-1 md"
              :src="require('@/assets/img/icon-crbook.png')"
            ></ion-img>
            Chromebook
          </div>
        </div>
        <div class="mt-6 flex items-center justify-between">
          <div>
            <div class="text-[#202124] text-[3.625rem] leading-tight">4.80</div>
            <div class="flex">
              <img
                class="w-3.5"
                src="@/assets/img/fullStar.png"
                style="width: 14px; height: 14px; margin-right: 2px"
              /><img
                class="w-3.5"
                src="@/assets/img/fullStar.png"
                style="width: 14px; height: 14px; margin-right: 2px"
              /><img
                class="w-3.5"
                src="@/assets/img/fullStar.png"
                style="width: 14px; height: 14px; margin-right: 2px"
              /><img
                class="w-3.5"
                src="@/assets/img/fullStar.png"
                style="width: 14px; height: 14px; margin-right: 2px"
              /><img
                class="w-3.5"
                src="@/assets/img/halfStar.png"
                style="width: 14px; height: 14px; margin-right: 2px"
              />
            </div>
            <div class="leading-loose my-1.5 text-xs">50000</div>
          </div>
          <div>
            <div class="text-[.6875rem] flex items-center justify-between">
              5
              <div class="ml-3.5 w-40 h-[.5625rem] bg-[#e3e3e3] rounded-md">
                <p class="h-full bg-[rgb(2,135,96)] rounded-md"></p>
              </div>
            </div>
            <div class="text-[.6875rem] flex items-center justify-between">
              4
              <div class="ml-3.5 w-40 h-[.5625rem] bg-[#e3e3e3] rounded-md">
                <p class="h-full w-4 bg-[rgb(2,135,96)] rounded-md"></p>
              </div>
            </div>
            <div class="text-[.6875rem] flex items-center justify-between">
              3
              <div class="ml-3.5 w-40 h-[.5625rem] bg-[#e3e3e3] rounded-md">
                <p class="h-full w-4 bg-[rgb(2,135,96)] rounded-md"></p>
              </div>
            </div>
            <div class="text-[.6875rem] flex items-center justify-between">
              2
              <div
                class="ml-3.5 w-40 h-[.5625rem] bg-[#e3e3e3] rounded-md"
              ></div>
            </div>
            <div class="text-[.6875rem] flex items-center justify-between">
              1
              <div
                class="ml-3.5 w-40 h-[.5625rem] bg-[#e3e3e3] rounded-md"
              ></div>
            </div>
          </div>
        </div>
        <div>
          <div class="mt-6">
            <div class="flex justify-between">
              <div class="flex items-center">
                <ion-img
                  class="w-8 h-8 rounded-full object-fill overflow-hidden md"
                  :src="
                    require('@/assets/img/939ff21415174dc5118eb885cf5a535c65f19ae276be1.jpeg')
                  "
                ></ion-img>
                <div class="ml-3">Miguel</div>
              </div>
              <ion-img
                class="w-1 md"
                :src="require('@/assets/img/icon-more.png')"
              ></ion-img>
            </div>
            <div class="flex items-center mt-4">
              <img
                class="star"
                src="@/assets/img/fullStar.png"
                style="width: 11px; height: 11px; margin-right: 2px"
              /><img
                class="star"
                src="@/assets/img/fullStar.png"
                style="width: 11px; height: 11px; margin-right: 2px"
              /><img
                class="star"
                src="@/assets/img/fullStar.png"
                style="width: 11px; height: 11px; margin-right: 2px"
              /><img
                class="star"
                src="@/assets/img/fullStar.png"
                style="width: 11px; height: 11px; margin-right: 2px"
              /><img
                class="star"
                src="@/assets/img/fullStar.png"
                style="width: 11px; height: 11px; margin-right: 2px"
              /><span class="text-xs ml-2">March 7, 2024</span>
            </div>
            <div class="mt-3">Jogo divertido com bônus enormes</div>
          </div>
          <div class="mt-6">
            <div class="flex justify-between">
              <div class="flex items-center">
                <ion-img
                  class="w-8 h-8 rounded-full object-fill overflow-hidden md"
                  :src="
                    require('@/assets/img/2569dc84ad21c232d1ad8c6c74cdaeb365f19d0a73dee.jpeg')
                  "
                ></ion-img>
                <div class="ml-3">Jose</div>
              </div>
              <ion-img
                class="w-1 md"
                :src="require('@/assets/img/icon-more.png')"
              ></ion-img>
            </div>
            <div class="flex items-center mt-4">
              <img
                class="star"
                src="@/assets/img/fullStar.png"
                style="width: 11px; height: 11px; margin-right: 2px"
              /><img
                class="star"
                src="@/assets/img/fullStar.png"
                style="width: 11px; height: 11px; margin-right: 2px"
              /><img
                class="star"
                src="@/assets/img/fullStar.png"
                style="width: 11px; height: 11px; margin-right: 2px"
              /><img
                class="star"
                src="@/assets/img/fullStar.png"
                style="width: 11px; height: 11px; margin-right: 2px"
              /><img
                class="star"
                src="@/assets/img/fullStar.png"
                style="width: 11px; height: 11px; margin-right: 2px"
              /><span class="text-xs ml-2">January 5, 2024</span>
            </div>
            <div class="mt-3">
              Jogo casual muito divertido, você também pode ganhar dinheiro
            </div>
          </div>
        </div>
        <div class="h-[1px] bg-[#dadce0] mt-10"></div>
      </div>
      <div class="mt-6">
        <div>
          <!---->
          <div class="mt-3.5" data-t="play_pass">Play Pass</div>
          <div class="mt-3.5" data-t="play_points">Play Points</div>
          <div class="mt-3.5" data-t="gift_cards">Vales-presente</div>
          <div class="mt-3.5" data-t="Resgatar">Resgatar</div>
          <div class="mt-3.5" data-t="refund_policy">Política de reembolso</div>
        </div>
        <div>
          <div class="mt-6 font-medium">Crianças e família</div>
          <div class="mt-3.5" data-t="family_guide">Guia para a família</div>
          <div class="mt-3.5" data-t="family_sharing">
            Compartilhamento em família
          </div>
        </div>
        <div class="flex items-center mt-6">
          <div class="mr-5" data-t="service_terms">Termos de Serviço</div>
          <div class="mr-5" data-t="privacy">Privacidade</div>
          <div class="mr-5" data-t="about_google_play">Sobre o</div>
        </div>
        <div class="flex items-center mt-6">
          <div class="mr-5" data-t="developers">Desenvolvedores</div>
          <!---->
          <div class="mr-5" data-t="language">
            Todos os preços incluem Tributo
          </div>
        </div>
      </div>
    </ion-content>
    <!-- 底部 -->
    <ion-footer>
      <ion-toolbar>
        <ion-tabs>
          <ion-router-outlet></ion-router-outlet>
          <ion-tab-bar slot="bottom">
            <ion-tab-button
              id="tab-button-game"
              class="md tab-has-label tab-has-icon tab-layout-icon-top ion-activatable ion-selectable ion-focusable"
              tab="home"
              href="/home"
            >
              <ion-icon
                class="tab-img text-2xl md"
                :src="require('@/assets/img/game.svg')"
              ></ion-icon>
              <ion-label>Listen now</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="home">
              <ion-icon
                class="tab-img text-2xl md"
                :src="require('@/assets/img/app.svg')"
              ></ion-icon>
              <ion-label>Apps</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="home">
              <ion-icon
                class="tab-img text-2xl md"
                :src="require('@/assets/img/file.svg')"
              ></ion-icon>
              <ion-label>Movies</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="home">
              <ion-icon
                class="tab-img text-2xl md"
                :src="require('@/assets/img/book.svg')"
              ></ion-icon>
              <ion-label>Books</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="home">
              <ion-icon
                class="tab-img text-2xl md"
                :src="require('@/assets/img/child.svg')"
              ></ion-icon>
              <ion-label>Kids</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
        </ion-tabs>
      </ion-toolbar>
    </ion-footer>
    <!-- 初始化加载 -->
    <ion-modal
      mode="md"
      id="modal-download-loading"
      class="md modal-default show-modal"
      ref="modalloading"
      :is-open="isOpenLoading"
    >
      <div class="ion-delegate-host ion-page" style="margin-top: 35%">
        <div
          class="w-56 flex flex-col items-center bg-white py-5 px-[.625rem] mx-auto rounded-lg"
        >
          <ion-spinner
            class="w-10 h-10 text-gray-400 md spinner-lines-sharp"
            role="progressbar"
          ></ion-spinner>
          <p class="text-black mt-4">Carregando...</p>
        </div>
      </div>
    </ion-modal>
    <!-- 点击下载按钮弹框 -->
    <ion-modal
      id="modal-install-download"
      ref="modal"
      trigger="modal-install-download"
      class="md modal-default show-modal"
    >
      <div class="ion-delegate-host ion-page" style="margin-top: 35%">
        <div
          class="w-80 bg-white text-black py-5 px-[.625rem] mx-auto rounded-2xl"
        >
          <div class="flex flex-col items-center">
            <div class="flex items-center">
              <ion-img
                class="w-7 mr-1.5 md"
                :src="require('@/assets/img/ic_raid_install.png')"
              ></ion-img>
              <p class="text-[1.75rem]">Instalação Rápida</p>
            </div>
            <p
              class="text-lg text-[#666] border border-[#747475] leading-tight px-3 rounded-[.1875rem] mt-2.5"
            >
              6 vezes mais rápido
            </p>
            <div
              class="flex items-center bg-[#0287601A] border border-[#028760] rounded-full px-3 my-5"
            >
              <ion-img
                class="w-4 mr-1.5 md"
                :src="require('@/assets/img/ic_actived.png')"
              ></ion-img>
              <p class="text-xl text-[#028760] leading-tight">Ativado</p>
            </div>
            <div class="w-full px-10">
              <div class="w-full" v-if="!countShow">
                <button @click="onInstall()" class="install">Instalar</button>
              </div>
              <!----><!---->
              <div
                class="flex justify-center relative w-11 h-11"
                id="count1"
                style="margin: 0 auto 0"
                v-if="countShow"
              >
                <ion-spinner
                  class="absolute w-full h-full md spinner-crescent"
                  name="circular"
                ></ion-spinner>
                <div
                  class="absolute w-full h-full flex justify-center items-center text-xs"
                >
                  {{ count }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>
    <!-- 进度条 -->
  </ion-page>
</template>

<script setup>
import {
  IonContent,
  IonFooter,
  IonPage,
  IonToolbar,
  IonImg,
  IonSpinner,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonIcon,
  IonTabs,
  IonRouterOutlet,
  IonModal,
} from "@ionic/vue";
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
/*
  安卓--下载apk
  ios--链接
  pc--安装pwa
  pwa-- 链接
*/
// 弹框
const modal = ref();
const dismiss = () => modal.value.$el.dismiss();

// 加载状态
const count = ref(0);
const countShow = ref(true);
const timer1 = ref(null);
function loadingFn(){
  timer1.value = setInterval(() => {
  if (count.value > 99) {
    clearInterval(timer1.value);
    count.value = `100`;
    countShow.value = false;
  } else {
    countShow.value = true;
    ++count.value;
  }
}, 50);
}



const gameUrl = ref("");
const downloadUrl = ref("https://9cuelwal.top/apk/afun_1719933971.apk");
const urlStrParmss = ref(route.query);

function setUrl() {
  let urlStrParms = "";
  for (const key in urlStrParmss.value) {
    if (Object.hasOwnProperty.call(urlStrParmss.value, key)) {
      const val = urlStrParmss.value[key];
      urlStrParms += `&${key}=${val}`;
    }
  }

  let url = "https://www.afun.com/?ch=2440015" + urlStrParms;
  gameUrl.value = url;
  if (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone ||
    document.referrer.includes("android-app://")
  ) {
    window.location.replace(gameUrl.value);
    // return false
  }
}
// 点击显示弹框
function modelFn(){
   count.value = 0
   loadingFn()
}
// 点击安装
function onInstall() {
  if (isAndroid.value) {
    window.location.href = downloadUrl.value;
  }
  if (isIOS.value) {
    window.location.href = gameUrl.value;
  }
  if (isPC.value) {
    if (deferredPrompt.value) {
      promptInstall();
    } else {
      window.location.href = gameUrl.value;
    }
  }
}
function promptInstall() {
  deferredPrompt.value.prompt();
  deferredPrompt.value.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === "accepted") {
      console.log("accepted");
      // this.setInstallFun();
    } else {
      console.log("no accepted");
    }
    deferredPrompt.value = null;
  });
}
const isAndroid = ref(false);
const isIOS = ref(false);
const isPC = ref(false);
const isWindows = ref(false);
const deferredPrompt = ref(false);
const isOpenLoading = ref(true)
// 判断当前是否是安卓、ios、pc
onMounted(() => {
  setUrl();
  const userAgent = navigator.userAgent.toLowerCase();
  // 判断是否是安卓
  if (userAgent.indexOf("android") > -1) {
    isAndroid.value = true;
  }

  // 判断是否是iOS
  if (
    userAgent.indexOf("iphone") > -1 ||
    userAgent.indexOf("ipad") > -1 ||
    userAgent.indexOf("ipod") > -1
  ) {
    isIOS.value = true;
  }

  // 假设除了安卓和iOS，其他都认为是PC
  if (!isAndroid.value && !isIOS.value) {
    isPC.value = true;
  }else{
    setTimeout(()=>{
        isOpenLoading.value = false
      },5000)
  }
  if (isPC.value) {
      window.addEventListener("beforeinstallprompt", (e) => {
        console.log("deferredPrompt", e);
        e.preventDefault();
        deferredPrompt.value = e;
        isOpenLoading.value = false
      });
      setTimeout(()=>{
        isOpenLoading.value = false
      },5000)
    
  }
  // 判断是否是 Windows
  if (userAgent.indexOf("windows") !== -1) {
    isWindows.value = true;
  }

});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>